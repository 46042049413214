import * as SocialIcons from '../../images/icons/social'
import siteData from '../../credentials-config'
import { Container } from '../../components/Container'

export const Footer = () => {
    return (
        <footer className="tw-bg-prime-dark tw-p-[16px] tw-pb-[50px]">
            <Container>
                <div className="tw-flex tw-flex-col tw-items-start tablet:tw-flex-row tablet:tw-items-center">
                    {/*<a*/}
                    {/*    href={pageCredentials.links.site.main}*/}

                    {/*>*/}
                    <img
                        src={
                            require('../../images/icons/logo/logo-white.svg')
                                .default
                        }
                        className={
                            'tw-block tw-w-[121px] tw-h-[15px] tw-mb-[24px] tablet:tw-mb-0'
                        }
                        width={156}
                        height={18}
                        alt="PrimeXBT logo"
                    />
                    {/*</a>*/}
                    <div className="tw-flex-row tw-flex tw-justify-between tablet:tw-justify-end tw-w-[100%]">
                        <a
                            className={'tablet:tw-mr-[24px] last:tw-mr-0'}
                            target={'_blank'}
                            href={siteData.links.social.angel}
                            title={'Angel'}
                        >
                            <SocialIcons.IconTelegram />
                        </a>
                        <a
                            className={'tablet:tw-mr-[24px] last:tw-mr-0'}
                            target={'_blank'}
                            href={siteData.links.social.facebook}
                            title={'Facebook'}
                        >
                            <SocialIcons.IconFacebook />
                        </a>
                        <a
                            className={'tablet:tw-mr-[24px] last:tw-mr-0'}
                            target={'_blank'}
                            href={siteData.links.social.twitter}
                            title={'Twitter'}
                        >
                            <SocialIcons.IconTwitter />
                        </a>
                        <a
                            className={'tablet:tw-mr-[24px] last:tw-mr-0'}
                            target={'_blank'}
                            href={siteData.links.social.youtube}
                            title={'YouTube'}
                        >
                            <SocialIcons.IconYouTube />
                        </a>
                        {/*<a className={'tablet:tw-mr-[24px] last:tw-mr-0'} target={'_blank'} href={pageCredentials.links.social.}><SocialIcons.IconInstagram /></a>*/}
                    </div>
                </div>
                <hr className="tw-my-[24px] tablet:tw-my-[32px] tw-border-[rgba(255,255,255,0.25)]" />
                <div className="tw-text-text-dimmed tw-text-[12px] tw-leading-[18px] desktop:tw-flex tw-justify-between">
                    <div className={'desktop:tw-max-w-[523px] tw-w-[100%]'}>
                        <div className="tw-text-white tw-mb-[16px] tablet:tw-mb-[32px]">
                            <p>PrimeXBT © {new Date().getUTCFullYear()}. All rights reserved.</p>
                            <p>
                                General information:{' '}
                                <a href={`mailto:${siteData.emails.info}`}>
                                    {siteData.emails.info}
                                </a>
                            </p>
                            <p>
                                Technical support:{' '}
                                <a href={`mailto:${siteData.emails.support}`}>
                                    {siteData.emails.support}
                                </a>
                            </p>
                        </div>
                        <div className="tw-mb-[10px] tablet:tw-mb-[20px]">
                            <p>
                                The company was founded in 2018 and from the
                                very first day, we have grown exponentially and
                                currently serve clients in more than 130
                                countries. We provide our clients with access to
                                top-tier liquidity and wide range of tools,
                                while maintaining security, liquidity, enabling
                                a safe and efficient environment for everyone.
                            </p>
                        </div>
                        <div className="tw-mb-[30px] desktop:tw-mb-0">
                            <p>
                                This website products and services are provided
                                by PrimeXBT Trading Services LLC.
                            </p>
                            <p>
                                PrimeXBT Trading Services LLC is incorporated in
                                St. Vincent and the Grenadines as an operating
                                subsidiary within the PrimeXBT group of
                                companies.
                            </p>
                        </div>
                    </div>
                    <div className="desktop:tw-max-w-[676px] tw-w-[100%]">
                        <div className="tw-mb-[10px] tablet:tw-mb-[20px]">
                            <p>
                                PRIMEXBT DOES NOT ACCEPT ANY USERS OR RESIDENTS
                                FROM UNITED STATES OF AMERICA, JAPAN, SAINT
                                VINCENT AND THE GRENADINES, CANADA, CUBA,
                                ALGERIA, ECUADOR, IRAN, SYRIA, NORTH KOREA OR
                                SUDAN, UNITED STATES MINOR OUTLYING ISLANDS,
                                AMERICAN SAMOA, RUSSIAN FEDERATION AND THE
                                COUNTRIES OR TERRITORIES WHERE ITS ACTIVITY
                                SHALL BE ESPECIALLY LICENSED, ACCREDITED OR
                                REGULATED BY OTHER WAYS. YOU SHALL CHECK YOUR
                                APPLICABLE LAW AND BE FULLY RESPONSIBLE FOR ANY
                                NEGATIVE IMPACT ARISEN FROM YOUR RESIDENCE
                                COUNTRY REGULATIONS. IF YOU ARE TRAVELLING TO
                                ANY OF THESE COUNTRIES, YOU ACKNOWLEDGE THAT OUR
                                SERVICES MAY BE UNAVAILABLE AND/OR BLOCKED IN
                                SUCH COUNTRIES.
                            </p>
                        </div>
                        <div>
                            <p>
                                The financial operations offered on this site
                                may involve increased risk. By using the
                                financial services and tools this site offers,
                                you may suffer serious financial loss, or
                                completely lose the funds in your guaranteed
                                trading account. Please evaluate all the
                                financial risks and seek advice from an
                                independent financial advisor before trading.
                                PrimeXBT is not responsible for any direct,
                                indirect or consequential losses, or any other
                                damages resulting from the user's actions on
                                this site.
                            </p>
                        </div>
                    </div>
                </div>
            </Container>
        </footer>
    )
}
