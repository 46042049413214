import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'

type CardsImages = string[]

interface StrategyCardsSliderProps {
    cardsImages: CardsImages
}

export function StrategyCardsSlider({ cardsImages }: StrategyCardsSliderProps) {
    return (
        <div>
            <Swiper
                slidesPerView={1}
                spaceBetween={24}
                pagination
                navigation
                modules={[Pagination, Navigation]}
                breakpoints={{
                    768: {
                        slidesPerView: 2,
                    },
                    1264: {
                        slidesPerView: 3,
                    },
                }}
            >
                {cardsImages.map((cardImage, key) => {
                    return (
                        <SwiperSlide key={key}>
                            <img
                                src={cardImage}
                                alt={'Trading strategy card'}
                                width={328}
                                height={381}
                                loading={'lazy'}
                            />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}
