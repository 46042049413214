export const IconFacebook = () => (
    <img
        src={require('./facebook.inline.svg').default}
        alt="Facebook"
        width={24}
        height={24}
    />
)

export const IconInstagram = () => (
    <img
        src={require('./instagram.inline.svg').default}
        alt="Instagram"
        width={24}
        height={24}
    />
)

export const IconTwitter = () => (
    <img
        src={require('./twitter.inline.svg').default}
        alt="Twitter"
        width={24}
        height={24}
    />
)

export const IconTelegram = () => (
    <img
        src={require('./telegram.inline.svg').default}
        alt="Telegram"
        width={24}
        height={24}
    />
)

export const IconYouTube = () => (
    <img
        src={require('./youTube.inline.svg').default}
        alt="YouTube"
        width={24}
        height={24}
    />
)
