import { Container } from '../Container'
import * as componentStyles from './style.module.scss'
import { StrategyCardsSlider } from '../StrategyCardsSlider'
import { ButtonExternalLink } from '../Button/Button'

interface StrategyCardsSectionProps {
    buttonLink?: string
    headerText: string
    cardsImages: Parameters<typeof StrategyCardsSlider>[0]['cardsImages']
    buttonText?: string
    subtitleText?: string
}

export function StrategyCardsSection({
    buttonLink,
    headerText,
    cardsImages,
    buttonText,
    subtitleText,
}: StrategyCardsSectionProps) {
    return (
        <section>
            <Container className={componentStyles.sectionSlider}>
                <h2>{headerText}</h2>
                {subtitleText ? <p>{subtitleText}</p> : null}
                <StrategyCardsSlider cardsImages={cardsImages} />
                {buttonText && buttonLink ? (
                    <ButtonExternalLink href={buttonLink}>
                        {buttonText}
                    </ButtonExternalLink>
                ) : null}
            </Container>
        </section>
    )
}
