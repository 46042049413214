import classNames from 'classnames'
import * as componentStyles from './button.component.module.scss'
import React from 'react'
import { Link } from 'gatsby'

export const ButtonExternalLink: React.FC<{
    className?: string
    children: React.ReactNode
    href: string
}> = ({ children, className, href }) => {
    return (
        <a
            className={classNames(componentStyles.button, className)}
            href={href}
        >
            {children}
        </a>
    )
}

export const ButtonInnerLink: React.FC<{
    className?: string
    children: React.ReactNode
    to: string
}> = ({ children, className, to }) => {
    return (
        <Link className={classNames(componentStyles.button, className)} to={to}>
            {children}
        </Link>
    )
}
