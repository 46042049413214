import { Container } from '../../components/Container'
import * as pageTheme from './copy-trading.page.module.scss'
import * as pageCss from './copy-trading.module.scss'
// import pageCredentials from './credentials-config'
import { Footer } from './Footer'
// import AppStoreIcon from './img/icons/mobiles/AppStore.inline.svg'
// import GooglePlayIcon from './img/icons/mobiles/GooglePlay.inline.svg'
import classNames from 'classnames'
import { ButtonExternalLink } from '../../components/Button/Button'
import { StrategyCardsSection } from '../../components/StrategyCardsSection'
import React, { useContext } from 'react'
import { CredentialsContext } from '../../context/credentials-context'
import { I18nContext } from '../../context/i18n-context'

const AwardIcon: React.FC<{
    image: string
    text: string
    className?: string
}> = ({ image, text, className }) => {
    return (
        <div className={classNames('tw-flex tw-items-center', className)}>
            <img
                src={require('./img/awards/laurel.svg').default}
                alt={'Left laurel icon'}
                width={32}
                height={68}
            />
            <img
                src={image}
                alt="Award icon"
                width={46}
                height={46}
                className={'tw-w-[46px] tw-ml-[14px]'}
            />
            <strong className="tw-mx-[14px] tw-max-w-[102px] tw-text-center tw-font-[400]">
                {text}
            </strong>
            <img
                src={require('./img/awards/laurel.svg').default}
                alt={'Right laurel icon'}
                width={32}
                height={68}
                className="tw-scale-x-[-1]"
            />
        </div>
    )
}

const HeaderSection = () => {
    const pageCredentials = useContext(CredentialsContext)
    const i18n = useContext(I18nContext)
    return (
        <section className={pageCss.headerSection}>
            <Container>
                <div className="tw-z-1 tw-relative">
                    <h1>{i18n.cov.hero.title}</h1>
                    <p>{i18n.cov.hero.subtitle}</p>
                    {/* prettier-ignore */}
                    <div className={pageCss.headerSectionSide}>
                        <iframe
                            src='https://www.youtube.com/embed/la3B2EDTTxw?controls=0&modestbranding=1&rel=0&showinfo=0&autoplay=1'
                            title='YouTube video player' frameBorder='0'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen></iframe>
                    </div>
                    <ButtonExternalLink
                        href={pageCredentials.links.platform.id.reg}
                    >
                        {i18n.cov.hero.button}
                    </ButtonExternalLink>
                    <div className="tw-mt-[32px] tablet:tw-mt-[70px] tablet:tw-flex tablet:tw-justify-center desktop:tw-justify-start">
                        <AwardIcon
                            image={require('./img/awards/1.svg').default}
                            text="Best Platform for Trading"
                            className="tw-mb-[20px] tablet:tw-mb-0 tablet:tw-inline-flex tablet:tw-mr-[32px] tw-justify-center tablet:tw-justify-start"
                        />
                        <AwardIcon
                            image={require('./img/awards/2.png').default}
                            text="Best Trading Application"
                            className="tablet:tw-inline-flex tw-justify-center tablet:tw-justify-start"
                        />
                    </div>
                </div>
            </Container>
        </section>
    )
}

const MobilesSection = () => {
    const i18n = useContext(I18nContext)

    return (
        <section
            className={classNames(
                'tw-pb-[31px] tablet:tw-py-[50px] desktop:tw-pb-[0]',
                pageCss.mobilesSection
            )}
        >
            <Container>
                <div className="tw-flex tw-flex-col-reverse tablet:tw-flex-row tablet:tw-justify-center tw-items-start">
                    {/* prettier-ignore */}
                    <img
                        srcSet={`${require('./img/mobile-platform-preview.png').default} 1x,
                                 ${require('./img/mobile-platform-preview@2x.png').default} 2x`}
                        src={require('./img/mobile-platform-preview.png').default}
                        alt={'Mobile platform preview'}
                        width={449}
                        height={531}
                        loading={'lazy'}
                    />
                    <div className="tablet:tw-max-w-[348px] desktop:tw-max-w-[592px] tw-mb-[31px] tablet:tw-mb-0 tablet:tw-ml-[24px] desktop:tw-ml-[112px]">
                        <h2 className='tw-text-center "tw-text-center tablet:tw-text-left tw-mb-[32px] tablet:tw-mb-[50px]'>
                            {i18n.cov.third.title}
                        </h2>
                        <p
                            className="tw-text-center tablet:tw-text-left tw-mb-[32px] tablet:tw-mb-[50px] tw-text-[16px] tablet:tw-text-[19px]"
                            dangerouslySetInnerHTML={{
                                __html: i18n.cov.third.subtitle,
                            }}
                        ></p>
                        {/*<div*/}
                        {/*    className={*/}
                        {/*        'tw-text-center tablet:tw-text-left tw-whitespace-nowrap'*/}
                        {/*    }*/}
                        {/*>*/}
                        {/*    <a*/}
                        {/*        href={pageCredentials.links.mobiles.appStore}*/}
                        {/*        target={'_blank'}*/}
                        {/*    >*/}
                        {/*        <AppStoreIcon*/}
                        {/*            className={'tw-inline tw-mr-[24px]'}*/}
                        {/*        />*/}
                        {/*    </a>*/}
                        {/*    <a*/}
                        {/*        href={pageCredentials.links.mobiles.googlePlay}*/}
                        {/*        target={'_blank'}*/}
                        {/*    >*/}
                        {/*        <GooglePlayIcon className={'tw-inline'} />*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </Container>
        </section>
    )
}

function SecondSection() {
    const pageCredentials = useContext(CredentialsContext)
    const i18n = useContext(I18nContext)

    return (
        <StrategyCardsSection
            cardsImages={[
                require('./img/strategies/1.svg').default,
                require('./img/strategies/2.svg').default,
                require('./img/strategies/3.svg').default,
            ]}
            subtitleText={i18n.cov.second.subtitle}
            headerText={i18n.cov.second.title}
            buttonLink={pageCredentials.links.platform.id.reg}
            buttonText={i18n.cov.second.button}
        />
    )
}

const CopyTradingPage = () => {
    return (
        <div className={pageTheme.covPage}>
            <header className="tw-absolute tw-z-[1] tw-top-0 tw-left-0 tw-right-0 tw-pt-[31px] tablet:tw-pt-[45px] ">
                <Container>
                    <img
                        src={
                            require('../../images/icons/logo/logo-white.svg')
                                .default
                        }
                        alt={'PrimeXBT logo'}
                        width={156}
                        height={18}
                        className="tw-w-[116px] tw-h-[14px] tablet:tw-w-[155px] tablet:tw-h-[18px]"
                    />
                </Container>
            </header>
            <main>
                <HeaderSection />
                <div className={pageCss.footerGradient}>
                    <SecondSection />
                    <MobilesSection />
                </div>
            </main>
            <Footer />
        </div>
    )
}

const Head = () => {
    const i18n = useContext(I18nContext)
    return (
        <>
            <title>{i18n.cov.hero.title}</title>
            <meta name="description" content={i18n.cov.hero.subtitle} />
        </>
    )
}

export { Head, CopyTradingPage }
