import {
    CopyTradingPage as CovPage,
    Head as CovHead,
} from '../_landings/copy-trading/copy-trading'
import { CredentialsContextProvider } from '../context/credentials-context'
import credentialsConfig, { Config } from '../credentials-config'
import { I18nContext } from '../context/i18n-context'

export default function ToolsTemplate({ pageContext }: any) {
    const cc: Config = {
        ...credentialsConfig,
        links: {
            ...credentialsConfig.links,
            platform: {
                ...credentialsConfig.links.platform,
                id: {
                    ...credentialsConfig.links.platform.id,
                    reg: pageContext.pageButtonsLink,
                },
            },
        },
    }

    return (
        <I18nContext.Provider value={pageContext.i18n}>
            <CredentialsContextProvider cc={cc}>
                <CovPage />
            </CredentialsContextProvider>
        </I18nContext.Provider>
    )
}

export const Head = ({ pageContext }: any) => {
    return (
        <I18nContext.Provider value={pageContext.i18n}>
            <CovHead />
        </I18nContext.Provider>
    )
}
